// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useAppApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refAppListTable = ref(null)
    const perPage = ref(localStorage.getItem('appPerPage') ?? 10)
    const totalApps = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const teamFilter = ref(null)
    const isLoading = ref(true)
    const appData = ref(null)
    const tableColumns = [
        {key: 'id', sortable: true},
        {key:'icon', sortable: false},
        {key: 'team', sortable: false, label: 'team'},
        {key: 'code', sortable: true, label: 'code'},
        {key: 'name', sortable: true},
        {key: 'created_by', sortable: true, label: 'Creator'},
        {key:'automation',sortable:true},
        {key: 'processing_status', sortable: true, label: 'Status'},
        {key: 'created_at', sortable: true},
        {key: 'actions'},
    ]
    const statusOptions = [
        {label: 'New', value: 'new'},
        {label: 'In Progress', value: 'processing'},
        {label: 'Completed', value: 'completed'},
        {label: 'Failed', value: 'error'},
    ]
    const developerUsers = ref(null)
    const threedArtistUsers = ref(null)
    const productManagerUsers = ref(null)
    const releaseBuildPermissionMembers = ref(null)
    const teamList = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refAppListTable.value ? refAppListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalApps.value,
        }
    })

    const refetchData = () => {
        localStorage.setItem('appPerPage', perPage.value)
        refAppListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter, teamFilter], () => {
        refetchData()
    })


    // ------------------------------------------------
    // Get App List
    // ------------------------------------------------
    const fetchApps = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store
            .dispatch('appStore/fetchApps', {
                sort: sort,
                page: currentPage.value,
                perPage: perPage.value,
                q: searchQuery.value,
                status: statusFilter.value,
                team: teamFilter.value,
                pagination: true
            })
            .then(response => {
                const {
                    data,
                    total
                } = response.data
                callback(data)
                totalApps.value = response.data.pagination.total
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Account List
    // ------------------------------------------------
    const fetchAccounts = (ctx, callback) => {
        store
            .dispatch('appStore/fetchAccounts')
            .then()
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get App Edit Detail
    // ------------------------------------------------
    const fetchEditApp = (ctx, callback) => {
        store
            .dispatch('appStore/fetchEditApp', {id: router.currentRoute.params.app})
            .then(response => {
                const {data} = response.data
                appData.value = data
                isLoading.value = false
            })
            .catch(error => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
                router.push('/app/list');
            })
    }

    // ------------------------------------------------
    // Get App Detail
    // ------------------------------------------------
    const fetchApp = (ctx, callback) => {
        store
            .dispatch('appStore/fetchApp', {id: router.currentRoute.params.app})
            .then(response => {
                const {data} = response.data
                appData.value = data
                isLoading.value = false
            })
            .catch(error => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
                router.push('/app/list');
            })
    }

    // ------------------------------------------------
    // Get User Role List
    // ------------------------------------------------
    const fetchUserRoleList = (ctx, callback) => {
        store
            .dispatch('appStore/fetchUserRoles', {
                role: ["Developer", "3D Artist", "Product Manager"]
            })
            .then(response => {
                const {data} = response.data
                developerUsers.value = data['Developer']
                threedArtistUsers.value = data['3D Artist']
                productManagerUsers.value = data['Product Manager']
                releaseBuildPermissionMembers.value = [...new Set([].concat(data['Developer'],data['3D Artist'],data['Product Manager']))].filter((item, index, self) =>
                  index === self.findIndex((t) => (
                    t.key === item.key && t.label === item.label
                  ))
                );

            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Team List
    // ------------------------------------------------
    const fetchTeamList = (ctx, callback) => {
        store
            .dispatch('teamStore/fetchTeams')
            .then(response => {
                const {data} = response.data
                teamList.value = data.map(item => ({value: item.id, label: item.name}))
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    return {
        tableColumns,
        perPage,
        currentPage,
        totalApps,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refAppListTable,
        refetchData,
        fetchApps,
        statusOptions,
        statusFilter,
        teamFilter,
        fetchAccounts,
        fetchApp,
        appData,
        isLoading,
        fetchEditApp,
        fetchUserRoleList,
        developerUsers,
        threedArtistUsers,
        productManagerUsers,
        releaseBuildPermissionMembers,
        teamList,
        fetchTeamList
    }
}
