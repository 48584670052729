<template>
    <b-row>
        <b-col cols="12" md="8">
            <b-card title="App Manual Create" v-if="!isLoading">
                <validation-observer ref="appForm" tag="form"
                                     @submit.prevent="validationFormControl().then(formSubmitted)">
                    <b-row class="align-items-center">
                        <b-col md="6">
                            <b-form-group label="Team" label-for="team_id">
                                <validation-provider #default="{ errors }" name="team_id" rules="required">
                                    <v-select id="team_id" v-model="appData.team_id" v-if="teamList"
                                              :options="teamList" :reduce="(option) => option.value"
                                              label="label" placeholder="Select Team" class="w-100">
                                    </v-select>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="d-flex align-items-center justify-content-between">
                            <div class="w-100 mr-1">
                                <b-form-group label="Icon" label-for="link">
                                    <b-form-file v-model="appData.icon" ref="file" @change="handleIconObject($event)"
                                        accept=".png">
                                        <template slot="file-name" slot-scope="{ names }">
                                            <b-badge variant="primary">
                                                {{ names[0] }}
                                            </b-badge>
                                        </template>
                                    </b-form-file>
                                </b-form-group>
                            </div>
                            <div>
                                <b-media vertical-align="center" id="appIcon">
                                    <template #aside>
                                        <b-avatar rounded="sm" size="45"
                                            :src="reviewIcon"
                                            :text="avatarText('G G')" :variant="`light-secondary`" />
                                    </template>
                                </b-media>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="App Name" label-for="name">
                                <validation-provider #default="{ errors }" name="App Name" maxlength="3"
                                                     rules="required">
                                    <b-form-input :state="errors.length > 0 ? false:null" placeholder="App Name"
                                                  @keydown="nameKeydown($event)" v-model="appData.name"/>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Code" label-for="code">
                                <validation-provider #default="{ errors }" name="code" rules="required">
                                    <b-form-input :state="errors.length > 0 ? false:null" placeholder="3 letter code"
                                                  :maxlength="3" v-model="appData.code"/>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <hr/>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Platforms" label-for="Platforms">
                                <app-collapse>
                                    <app-collapse-item class="app-client">
                                        <template #header>
											<span class="lead collapse-title d-flex align-items-center">
												<b-form-checkbox @click="changeBoolVal('ios')"
                                                                 class="app-platform-checkbox" v-model="appData.has_ios"
                                                                 value="true"
                                                                 disabled required>
												</b-form-checkbox>
												<span @click="changeBoolVal('ios')"
                                                      class="app-platform-title">IOS</span>
											</span>
                                        </template>
                                        <b-row>
                                            <b-col md="12">
                                                <validation-provider #default="{ errors }" name="Appstore Account"
                                                                     :rules="this.appData.has_ios?'required':''">
                                                    <b-form-group label="Appstore Account" label-for="Appstore Account"
                                                                  :state="errors.length > 0 ? false:null">
                                                        <v-select :options="appAccounts.appstoreAccounts"
                                                                  :reduce="(option) => option.id" label="name"
                                                                  placeholder="Select Appstore Account" class="w-100"
                                                                  v-model="appData.appstore_account_id">
                                                        </v-select>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Bundle Identifier" label-for="Bundle Identifier">
                                                    <validation-provider #default="{ errors }"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Bundle Identifier"
                                                                      v-model="appData.bundle"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Appstore ID" label-for="Appstore ID">
                                                    <validation-provider #default="{ errors }" name="Appstore ID"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Appstore ID"
                                                                      v-model="appData.appstore_id"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Tiktok ID" label-for="Tiktok ID">
                                                    <b-form-input placeholder="Tiktok ID"
                                                                  v-model="appData.tiktok_ios_id"/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Adjust ID" label-for="Adjust ID">
                                                    <validation-provider #default="{ errors }" name="Adjust ID"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Adjust ID"
                                                                      v-model="appData.adjust_ios_token"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Applovin Inter ID" label-for="Applovin Inter ID">
                                                    <validation-provider #default="{ errors }" name="Applovin Inter ID"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Applovin Inter ID"
                                                                      v-model="appData.applovin_ios_inter"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Applovin Reward ID" label-for="Applovin Reward ID">
                                                    <validation-provider #default="{ errors }" name="Applovin Reward ID"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Applovin Reward ID"
                                                                      v-model="appData.applovin_ios_reward"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Applovin Banner ID" label-for="Applovin Banner ID">
                                                    <validation-provider #default="{ errors }" name="Applovin Banner ID"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Applovin Banner ID"
                                                                      v-model="appData.applovin_ios_banner"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Admob App ID" label-for="Admob App ID">
                                                    <b-form-input placeholder="Admob App ID" type="text" v-model="appData.admob_ios_app_id"/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-form-group label="Provision File" label-for="link">
                                                    <b-form-file v-model="appData.provision" ref="file"
                                                        @change="handleProvisionObject($event)"
                                                        accept=".mobileprovision">
                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="primary">
                                                                {{ names[0] }}
                                                            </b-badge>
                                                        </template>
                                                    </b-form-file>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-form-group label="Certificate File" label-for="link">
                                                    <b-form-file v-model="appData.certificate" ref="file"
                                                        @change="handleCertificateObject($event)"
                                                        accept=".p12">
                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="primary">
                                                                {{ names[0] }}
                                                            </b-badge>
                                                        </template>
                                                    </b-form-file>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </app-collapse-item>
                                    <app-collapse-item title="Andorid" class="app-client">
                                        <template #header>
											<span class="lead collapse-title d-flex align-items-center">
												<b-form-checkbox @click="changeBoolVal('android')"
                                                                 class="app-platform-checkbox"
                                                                 v-model="appData.has_android"
                                                                 value="true" disabled required>
												</b-form-checkbox>
												<span @click="changeBoolVal('android')"
                                                      class="app-platform-title">Android</span>
											</span>
                                        </template>
                                        <b-row>
                                            <b-col md="6">
                                                <validation-provider #default="{ errors }" name="Google Play Account"
                                                                     :rules="this.appData.has_android?'required':''">
                                                    <b-form-group label="Google Play Account"
                                                                  label-for="Google Play Account"
                                                                  :state="errors.length > 0 ? false:null">
                                                        <v-select :options="appAccounts.androidAccounts"
                                                                  :reduce="(option) => option.id" label="name"
                                                                  placeholder="Select Google Play Account" class="w-100"
                                                                  v-model="appData.android_account_id">
                                                        </v-select>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Package Name" label-for="Package Name">
                                                    <validation-provider #default="{ errors }" name="Package Name"
                                                                         :rules="this.appData.has_android?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Package Name"
                                                                      v-model="appData.package_name"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Tiktok ID" label-for="Tiktok ID">
                                                    <b-form-input placeholder="Tiktok ID"
                                                                  v-model="appData.tiktok_android_id"/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Adjust ID" label-for="Adjust ID">
                                                    <validation-provider #default="{ errors }" name="Adjust ID"
                                                                         :rules="this.appData.has_android?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Adjust ID"
                                                                      v-model="appData.adjust_android_token"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Applovin Inter ID" label-for="Applovin Inter ID">
                                                    <validation-provider #default="{ errors }" name="Applovin Inter ID"
                                                                         :rules="this.appData.has_android?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Applovin Inter ID"
                                                                      v-model="appData.applovin_android_inter"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Applovin Reward ID" label-for="Applovin Reward ID">
                                                    <validation-provider #default="{ errors }" name="Applovin Reward ID"
                                                                         :rules="this.appData.has_android?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Applovin Reward ID"
                                                                      v-model="appData.applovin_android_reward"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Applovin Banner ID" label-for="Applovin Banner ID">
                                                    <validation-provider #default="{ errors }" name="Applovin Banner ID"
                                                                         :rules="this.appData.has_android?'required':''">
                                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                                      placeholder="Applovin Banner ID"
                                                                      v-model="appData.applovin_android_banner"/>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="6">
                                                <b-form-group label="Admob App ID" label-for="Admob App ID">
                                                    <b-form-input placeholder="Admob App ID" type="text" v-model="appData.admob_android_app_id"/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </app-collapse-item>
                                </app-collapse>
                                <span v-if="platformError" class="text-danger font-12">Please select platform</span>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <hr class="mt-0"/>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Facebook App ID" label-for="Facebook App ID">
                                <validation-provider #default="{ errors }" name="Facebook App ID" rules="required">
                                    <b-form-input :state="errors.length > 0 ? false:null" placeholder="Facebook App ID"
                                                   v-model="appData.facebook_id"/>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Facebook Client Token" label-for="Facebook Client Token">
                            <b-form-input placeholder="Facebook Client Token" type="number" v-model="appData.facebook_client_token"/>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Snapchat App ID" label-for="Snapchat App ID">
                                <b-form-input placeholder="Snapchat App ID" v-model="appData.snapchat_id"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Tiktok Advertiser Account" label-for="Tiktok Advertiser Account">
                                <v-select :options="appAccounts.tiktokAccounts" :reduce="(option) => option.id"
                                          label="name" placeholder="Select Tiktok Advertiser Account" class="w-100"
                                          v-model="appData.tiktok_account_id">
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Elephant Game ID" label-for="Elephant Game ID">
                            <b-form-input placeholder="Elephant Game ID" v-model="appData.elephant_game_id"/>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Elephant Game Secret" label-for="Elephant Game Secret">
                            <b-form-input placeholder="Elephant Game Secret" v-model="appData.elephant_game_secret"/>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Asana ID" label-for="Asana ID">
                                <b-form-input placeholder="Asana ID" v-model="appData.asana_id"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Bitbucket Repo" label-for="Bitbucket Repo">
                                <b-form-input placeholder="Bitbucket Repo" v-model="appData.repo"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <hr/>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Product Manager" label-for="Product Manager" v-if="productManagerUsers"
                                          id="memberSelect">
                                <v-select
                                    v-model="appData.productManagers"
                                    :reduce="(option) => option.key"
                                    label="label"
                                    :options="productManagerUsers"
                                    :clearable="false"
                                    placeholder="Select User"
                                    multiple
                                >
                                    <template #option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                    <template #selected-option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Developer" label-for="Developer" v-if="developerUsers"
                                          id="memberSelect">
                                <v-select
                                    v-model="appData.developers"
                                    :reduce="(option) => option.key"
                                    label="label"
                                    :options="developerUsers"
                                    :clearable="false"
                                    placeholder="Select User"
                                    multiple
                                >
                                    <template #option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                    <template #selected-option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="3D Artist" label-for="3D Artist" v-if="threedArtistUsers"
                                          id="memberSelect">
                                <v-select
                                    v-model="appData.threeDArtists"
                                    :reduce="(option) => option.key"
                                    label="label"
                                    :options="threedArtistUsers"
                                    :clearable="false"
                                    placeholder="Select User"
                                    multiple
                                >
                                    <template #option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                    <template #selected-option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                          <b-col md="12">
                            <b-form-group label="Release Build Perm. Members" label-for="Release Build Perm. Members" v-if="releaseBuildPermissionMembers"
                                          id="memberSelect">
                                <v-select
                                    v-model="appData.releaseBuildPermissionMembers"
                                    :reduce="(option) => option.key"
                                    label="label"
                                    :options="releaseBuildPermissionMembers"
                                    :clearable="false"
                                    placeholder="Select User"
                                    multiple
                                >
                                    <template #option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                    <template #selected-option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <hr/>
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
                                Submit
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </b-card>
            <b-card v-if="isLoading" class="mt-2">
                <div class="text-center my-2">
                    <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                    <strong>Content is being created, please wait...</strong>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@/components/AppCollapse.vue'
import AppCollapseItem from '@/components/AppCollapseItem.vue'
import useAppApi from '@/composables/useAppApi'
import router from '@/router'
import {required} from '@validations'
import {mapState} from 'vuex'
import {avatarText} from '@core/utils/filter'
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BSpinner,
    BAvatar,
    BFormFile,
    BBadge,
    BMedia,
} from 'bootstrap-vue'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCard,
        BCol,
        BFormGroup,
        BForm,
        BButton,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
        BFormCheckbox,
        AppCollapse,
        AppCollapseItem,
        BSpinner,
        BAvatar,
        BFormFile,
        BBadge,
        BMedia
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            appData: {
                has_ios: false,
                has_android: false,
            },
            uploadLoad: false,
            required,
            isLoading: false,
            platformError: false,
            reviewIcon:null,
        }
    },
    methods: {
        formSubmitted() {
            if (!this.appData.has_android && !this.appData.has_ios) {
                this.platformError = true
            } else {
                this.isLoading = true
                const formData = new FormData()
                Object.entries(this.appData).forEach(([key, value]) => {
                    if(key ==='developers' || key === 'threeDArtists' || key === 'productManagers' || key === 'releaseBuildPermissionMembers') formData.append(key,JSON.stringify(value))
                    else if(value !== null)formData.append(key, value)
                })
                this.$store.dispatch('appStore/addAppManual', formData)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully added!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        router.push({
                            name: 'app-detail',
                            params: {
                                app: response.data.data.id
                            }
                        })
                    }).catch((errors) => {
                    console.log(errors)
                    this.isLoading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please check form',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: errors.response ? errors.response.data.message : ''
                        },
                    })
                })
            }
        },
        validationFormControl() {
            return new Promise((resolve, reject) => {
                this.$refs.appForm.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        changeBoolVal(type) {
            if (type === 'ios') this.appData.has_ios = !this.appData.has_ios
            else this.appData.has_android = !this.appData.has_android
            if (this.appData.has_ios || this.appData.has_android) this.platformError = false
        },
        nameKeydown(e) {
            if (e.key === "-") e.preventDefault()
        },
        handleProvisionObject(event) {
			this.appData.provision = event.target.files[0];
		},
        handleCertificateObject(event) {
			this.appData.certificate = event.target.files[0];
		},
        handleIconObject(event){
            this.appData.icon = event.target.files[0]
            if(!this.appData.icon) this.reviewIcon = null
            else this.reviewIcon = URL.createObjectURL(this.appData.icon)
        }
    },
    setup() {
        const {
            fetchAccounts,
            fetchUserRoleList,
            developerUsers,
            threedArtistUsers,
            productManagerUsers,
            releaseBuildPermissionMembers,
            teamList,
            fetchTeamList
        } = useAppApi()
        return {
            fetchAccounts,
            fetchUserRoleList,
            developerUsers,
            threedArtistUsers,
            productManagerUsers,
            releaseBuildPermissionMembers,
            teamList,
            fetchTeamList,
            avatarText
        }
    },
    mounted() {
        this.fetchAccounts()
        this.fetchUserRoleList()
        this.fetchTeamList()
    },
    computed: {
        ...mapState('appStore', ['appAccounts'])
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.app-client {
    .card-header {
        position: relative;
        padding: 10px 0px !important;
    }

    .app-platform-checkbox {
        z-index: 0px !important;
    }

    .app-platform-title {
        width: 80vw;
        line-height: 21px;
        padding: 10px 0px;
        position: absolute;
        padding-left: 30px;
        z-index: 3;
    }

    .font-12 {
        font-size: 12px
    }
}

#memberSelect {
    .vs__selected {
        background-color: #dadadb !important;
        color: #232323 !important;
    }
}
#appIcon{
    .media-aside{
        margin-right: 0px!important;
    }
}
</style>
